import React, { useEffect } from 'react';
import bg1 from '../../assets/b4.JPG'; // Correct import path

import bg3 from '../../assets/w5.png'; // Correct import path

import AOS from 'aos';
import 'aos/dist/aos.css';

const Company = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className='relative overflow-hidden'>
      {/* Full Background Image */}
      <img src={bg1} alt="Background" className="absolute inset-0 z-0 object-cover w-full h-full" style={{ opacity: '0.5' }} />
      
      {/* Overlay */}
      <div className="absolute inset-0 z-10 bg-black opacity-80"></div>

      {/* Content */}
      <div className='relative z-20 max-w-[850px] mx-auto text-center text-white py-16 px-4'>
        <h1 className='text-3xl md:text-4xl font-bold mb-6' data-aos="fade-up">
          DIGITAL & SOCIAL MEDIA MARKETING
        </h1>
        <p className='mb-4'>
          We are experts in omnichannel content, channel, and performance marketing. Our team uses different digital channels, such as websites, social media platforms, search engines, email, and mobile apps, to promote and reach target audiences. It encompasses various strategies and tactics, including search engine optimization (SEO), search engine marketing (SEM), content marketing, email marketing, influencer marketing, and social media marketing.
        </p>
        <p>
          Our social media services focus on utilizing social media platforms to connect and engage with target audiences. It involves creating and sharing content, interacting with users, running ad campaigns, and analyzing data to drive brand awareness, customer engagement, and lead generation.
        </p>
            
        <ul className="list-disc ml-6 mb-6">
          <li>Social media platforms, such as Facebook, Instagram, LinkedIn, YouTube, and TikTok, have become powerful marketing tools due to their widespread popularity and large user bases. They allow businesses and individuals to showcase their products or services, build brand loyalty, interact with customers, generate leads, and drive website traffic.</li>
        </ul>
        

        {/* Centered Image */}
 
        <div className="w-full h-1 bg-gradient-to-r from-black via-[#ffe034] to-black mb-8" data-aos="fade-up"></div>

        {/* Description */}
        <div className='text-sm md:text-base flex justify-center' data-aos="fade-up">
   
          <div className="flex justify-center">
          <img className='w-[500px] my-4 mr-2 animate__animated animate__bounce animate__slow' src={bg3} alt='Centered' />
        </div>

          </div>
     
        </div>
      </div>

  );
};

export default Company;
