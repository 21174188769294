import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';

import bgVideo from '../../assets/bg44.mp4'; // Import the background video

const SocialMediaList = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error('Error trying to play the video:', error);
      });
    }
  }, []);

  return (
    <div className='relative h-screen'>
      <video ref={videoRef} loop muted className='absolute top-0 left-0 w-full h-full object-cover'>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <div className='absolute inset-0 bg-black opacity-50'></div>
      {/* Content */}
      <div className='absolute inset-0 bg-black opacity-5'></div>
      <div className='max-w-[1240px] mx-auto relative z-10'>
        {/* Facebook */}
        <div className='flex flex-col justify-center py-8'>
          <h1 className='md:text-2xl sm:text-3xl text-white font-bold py-1 text-center'>FACEBOOK DAILY TRIBUNE</h1>
          <p className='sm:text-1xl text-[#2f78ff] font-bold text-center'>/TRIBUNEPHL 708K likes • 1.2M followers</p>
          <p className='text-white text-sm text-center'>
            Stay tuned with us! We bring you fresh news and updates daily. Make sure to follow us to never miss out on the latest stories.
          </p>
          <div className='flex justify-center'>
            <Link to="https://www.facebook.com/tribunephl">
              <button className='relative bg-black-400 bg-opacity-10 text-white w-[200px] rounded-md font-medium my-6 py-3'>
                CLICK HERE TO STAY INFORMED
                <span className="absolute bottom-0 left-0 h-[3px] w-full rounded-full bg-gradient-to-r from-black via-[#ff2424] to-black"></span>
              </button>
            </Link>
          </div>
        </div>
        
        {/* Instagram */}
        <div className='flex flex-col justify-center py-8'>
          <h1 className='md:text-2xl sm:text-3xl text-white font-bold py-1 text-center'>INSTAGRAM DAILY NEWS</h1>
          <p className='sm:text-1xl text-[#ff2400] font-bold text-center'>@dailynews 1.5M followers</p>
          <p className='text-white text-sm text-center'>
            Follow us on Instagram for stunning visuals and quick updates on the go!
          </p>
          <div className='flex justify-center'>
            <Link to="https://www.instagram.com/dailynews">
              <button className='relative bg-black-400 bg-opacity-10 text-white w-[200px] rounded-md font-medium my-6 py-3'>
                FOLLOW NOW
                <span className="absolute bottom-0 left-0 h-[3px] w-full rounded-full bg-gradient-to-r from-black via-[#ff2424] to-black"></span>
              </button>
            </Link>
          </div>
        </div>
        
        {/* YouTube */}
        <div className='flex flex-col justify-center py-8'>
          <h1 className='md:text-2xl sm:text-3xl text-white font-bold py-1 text-center'>YOUTUBE CHANNEL</h1>
          <p className='sm:text-1xl text-[#ff0000] font-bold text-center'>TRIBUNE NOW</p>
          <p className='text-white text-sm text-center'>
            Subscribe to our YouTube channel for video updates and tutorials.
          </p>
          <div className='flex justify-center'>
            <Link to="https://www.youtube.com/channel">
              <button className='relative bg-black-400 bg-opacity-10 text-white w-[200px] rounded-md font-medium my-6 py-3'>
                SUBSCRIBE NOW
                <span className="absolute bottom-0 left-0 h-[3px] w-full rounded-full bg-gradient-to-r from-black via-[#ff2424] to-black"></span>
              </button>
            </Link>
          </div>
        </div>
        
        {/* Viber */}
        <div className='flex flex-col justify-center py-8'>
          <h1 className='md:text-2xl sm:text-3xl text-white font-bold py-1 text-center'>VIBER COMMUNITY</h1>
          <p className='sm:text-1xl text-[#5900b3] font-bold text-center'>KATRIBU VIBER COMMUNITY</p>
          <p className='text-white text-sm text-center'>
            Join our Viber community for discussions and updates.
          </p>
          <div className='flex justify-center'>
            <Link to="https://invite.viber.com/community">
              <button className='relative bg-black-400 bg-opacity-10 text-white w-[200px] rounded-md font-medium my-6 py-3'>
                JOIN NOW
                <span className="absolute bottom-0 left-0 h-[3px] w-full rounded-full bg-gradient-to-r from-black via-[#ff2424] to-black"></span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaList;
