import React, { useEffect } from 'react';
import bg1 from '../../assets/bg1.png'; // Correct import path

import bg2 from '../../assets/33.png'; // Correct import path
import bg3 from '../../assets/32.png'; // Correct import path
import AOS from 'aos';
import 'aos/dist/aos.css';

const Company = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className='w-full bg-black py-16 px-4 relative'>
      {/* Background Image */}
      <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${bg1})`, opacity: '0.1' }} />
      
      {/* Content */}
      <div className='max-w-[850px] mx-auto text-center'>
        <h1 className='text-3xl md:text-4xl text-white font-bold mb-6' data-aos="fade-up">
          PUBLIC RELATIONS
        </h1>
        <div className="w-full h-1 bg-gradient-to-r from-black via-[#ffe034] to-black mb-8" data-aos="fade-up"></div>

        {/* Description */}
        <div className='text-white text-sm md:text-base' data-aos="fade-up">
          <p className='mb-4'>
            <strong>Our Public Relations Services Include:</strong>
          </p>
          <ul className="list-disc ml-6 mb-6">
            <li data-aos="fade-up">MEDIA RELATIONS: managing relationships with journalists, bloggers, and other media professionals to secure positive media coverage and handle crisis communications.</li>
            <li data-aos="fade-up">PRESS RELEASE CREATION AND DISTRIBUTION: crafting and distributing official statements about company news, events, product launches, or other updates to generate media and public interest.</li>
            <li data-aos="fade-up">CRISIS MANAGEMENT: developing strategies and communication plans to effectively handle and mitigate negative incidents or crises that may impact clients' reputations.</li>
            <li data-aos="fade-up">REPUTATION MANAGEMENT: monitoring and managing the online reputation of a company or individual, including addressing negative reviews, managing online presence, and responding to feedback.</li>
            <li data-aos="fade-up">STAKEHOLDER ENGAGEMENT: building relationships with key stakeholders, such as customers, employees, investors, and community members, to foster trust and support for an organization.</li>
            <li data-aos="fade-up">EVENT PLANNING AND MANAGEMENT: organizing and promoting events, such as conferences, press conferences, product launches, and trade shows, to generate media coverage, attract attendees, and enhance brand visibility.</li>
            <li data-aos="fade-up">SOCIAL MEDIA MANAGEMENT: developing and executing social media strategies to engage with the target audience, share relevant content, and enhance brand perception.</li>
          </ul>
          <p data-aos="fade-up">Our public relations services help organizations, companies, and individuals shape their narratives, build brand credibility, manage crises, and ultimately cultivate positive public perception. These efforts foster trust, attract customers, and boost growth.</p>
        </div>
        <div className="w-full h-1 bg-gradient-to-r from-black via-[#ffe034] to-black mb-8" data-aos="fade-up"></div>

        {/* Description */}
        <div className='text-sm md:text-base flex justify-center' data-aos="fade-up">
          {/* First Column */}
          <div className="w-full md:w-1/2 md:mr-4">
  <img className='w-[500px] my-4 mr-2 animate__animated animate__bounce animate__slow' src={bg2} alt='Background 2' />
</div>
{/* Second Column */}
<div className="w-full md:w-1/2 md:ml-4">
  <img className='w-[500px] my-4 mr-2 animate__animated animate__bounce animate__slow' src={bg3} alt='Background 3' />
  {/* Add your content for the second column here */}
</div>

        </div>
        
  </div>
    </div>
  );
};

export default Company;
