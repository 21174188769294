import React from 'react';
import { Grid, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram'; // Import InstagramIcon
import TikTokIcon from '../../assets/tiktok.png';
import XLogo from '../../assets/x.png'; // Import X logo
import ViberIcon from '../../assets/viber.png'; // Import Viber logo
import BackgroundVideo from '../../assets/articial.mp4'; // Import your background video

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent', // Transparent background
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%', // Ensure the height stretches to fill the container
}));

const SocialButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'block',
  width: '100%', // Make the button fill the width
}));

const MyGridComponent = () => {
  return (
    <>
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <video autoPlay muted loop style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} preload="auto">
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
        <Grid container spacing={3} style={{ padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: 'blur(5px)' }}> {/* Transparent background */}
          <Grid item xs={12} sm={4}>
            <Item>
              <YouTubeIcon fontSize="large" style={{ color: '#FF0000' }} />
              <h1 style={{ color: '#ffffff' }}>TRIBUNE NOW</h1>
              <p style={{ color: '#ffffff' }}>@TribuneNOW
‧
24.2K subscribers
‧
7.6K videos</p>
              <SocialButton
                variant="contained"
                color="secondary"
                href="https://www.youtube.com/c/TribuneNOW"
                target="_blank"
              >
                Follow on YouTube
              </SocialButton>
            </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Item>
              <FacebookIcon fontSize="large" style={{ color: '#1877F2', marginBottom: '8px' }} />
              <h1 style={{ color: '#ffffff' }}>FACEBOOK DAILY TRIBUNE</h1>
              <p style={{ color: '#ffffff' }}>/TRIBUNEPHL 708K likes • 1.2M followers</p>
              <SocialButton
                variant="contained"
                color="primary"
                href="https://www.facebook.com/tribunephl"
                target="_blank"
              >
                Follow on Facebook
              </SocialButton>
            </Item>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Item>
              <img src={XLogo} alt="X Logo" style={{ height: '40px' }} />
              <h1 style={{ color: '#ffffff' }}>X(TWITTER)</h1>
              <p style={{ color: '#ffffff' }}>394 Following
13.3K Followers</p>
              <SocialButton
                variant="contained"
                color="primary"
                href="https://x.com/tribunephl"
                target="_blank"
              >
                Follow on X
              </SocialButton>
            </Item>
          </Grid>
          {/* Viber Group */}
          <Grid item xs={12} sm={4}>
            <Item>
              <img src={ViberIcon} alt="Viber Logo" style={{ height: '40px' }} />
              <h1 style={{ color: '#ffffff' }}>Viber Group</h1>
              <p style={{ color: '#ffffff' }}>Join our Viber group for updates</p>
              <SocialButton
                variant="contained"
                color="primary"
                href="https://invite.viber.com/?g2=AQBwtzjrHGJ7bUsRn8kbAfGyHvApTbR9SWfnW4C2GyluTUtXKMjF%2Fs8%2FDhvkjstl&lang=en"
                target="_blank"
              >
                Join on Viber
              </SocialButton>
            </Item>
          </Grid>
          {/* Instagram */}
          <Grid item xs={12} sm={4}>
            <Item>
              <InstagramIcon fontSize="large" style={{ color: '#E1306C', marginBottom: '8px' }} />
              <h1 style={{ color: '#ffffff' }}>INSTAGRAM</h1>
              <p style={{ color: '#ffffff' }}>394 Following
13.3K Followers</p>
              <SocialButton
                variant="contained"
                color="primary"
                href="https://www.instagram.com/dailytribunephl"
                target="_blank"
              >
                Follow on Instagram
              </SocialButton>
            </Item>
          </Grid>
          {/* TikTok */}
          <Grid item xs={12} sm={4}>
            <Item>
            <img src={TikTokIcon} alt="TikTok Icon" style={{ height: '40px' }} />
              <h1 style={{ color: '#ffffff' }}>TIKTOK</h1>
              <p style={{ color: '#ffffff' }}>Follow us on TikTok for more</p>
              <SocialButton
                variant="contained"
                color="primary"
                href="https://www.tiktok.com/@dailytribuneofficial"
                target="_blank"
              >
                Follow on TikTok
              </SocialButton>
            </Item>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default MyGridComponent;
