import React from 'react';
import { ReactTyped } from "react-typed";
import bg1 from '../../assets/dep21.jpg'; // Assuming correct import path for the background image

const Hero = () => {
  return (
    <div className='text-white'>
      {/* Background image */}
      <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${bg1})`, opacity: '0.1' }} />
      
      {/* Main content */}
      <div className='max-w-[850px] mt-16 sm:mt-24 md:mt-32 mx-auto text-center flex flex-col justify-center px-4'>

        {/* Title */}
        <h1 className='md:text-3xl sm:text-2xl text-xl font-bold py-2'>
          ABOUT COMPANY
        </h1>
        
        {/* Company description */}
        <div className='flex justify-center items-center'>
          <p className='md:text-xl sm:text-lg text-base font-normal text-gray-500'>
            At Avietho Digital Marketing, we live and breathe digital marketing. Our team is comprised of creative thinkers, data-driven analysts, and certified marketing experts who are obsessed with driving growth for our clients. Founded in 2018, Avietho has been a pioneer in the digital marketing space, constantly evolving our strategies and solutions to keep pace with the ever-changing digital landscape. We utilize cutting-edge tools and tactics to craft campaigns that capture attention, inspire engagement, and maximize ROI across all digital channels.
          </p>
        </div>
        
        {/* Typed text */}
        <ReactTyped
          className='md:text-1xl sm:text-1xl text-xl font-bold md:pl-2 pl-2'
          strings={["Innovate. Influence. Impact: Your Trusted Advertising Partner"]}
          typeSpeed={40}
          backSpeed={140}
          loop
        />
        
        {/* Core values */}
        <h1 className='md:text-2xl sm:text-2xl text-2xl text-[#ffffff] font-bold py-2 mt-8'>
          OUR CORE VALUES
        </h1>
        <div style={{ marginBottom: "30px", fontWeight: "normal", color: "#ffffff" }}>
          <b>Transparency - </b>We practice full transparency with our clients, providing clear insights into our process, progress, and performance metrics.<br />
          <b>Ingenuity - </b>Thinking outside the box and challenging conventions allows us to find creative solutions tailored to our clients' unique needs.<br />
          <b>Integrity - </b>We operate with the highest levels of integrity, honesty, and accountability in everything we do.<br />
          <b>Passion - </b>Our team is passionate about digital marketing and driven to achieve superior results.<br />
          <b>Growth - </b>We are committed to continuous education to grow our knowledge and provide our clients with leading digital marketing strategies.
        </div>

        {/* Approach */}
        <div>
          <h1 className='md:text-2xl sm:text-2xl text-2xl text-[#ffffff] font-bold py-2'>
            OUR APPROACH
          </h1>
          <p>We take an agile, insights-driven approach to maximize the impact of our clients' digital marketing programs. This process includes:</p>
          <ul>
            <li>Discovery & strategy</li>
            <li>Campaign planning & management</li>
            <li>Continuous optimization & analytics</li>
            <li>Creative development & content production</li>
          </ul>
        </div>

        {/* Mission and Vision */}
        <div className="w-full h-1 bg-gradient-to-r from-black via-[#ffe034] to-black mb-8" ></div>
        <div className='text-sm md:text-base flex justify-center' >
          {/* First Column - Mission */}
          <div className="w-full md:w-1/2 md:mr-4">
            <h1 className='md:text-2xl sm:text-2xl text-2xl text-[#ffffff] font-bold py-2'>
              MISSION
            </h1>
            <p>With God-given talents, combined with the experience and dedication of our team, we endeavor to render our services with distinction and excellence.</p>
          </div>
          {/* Second Column - Vision */}
          <div className="w-full md:w-1/2 md:ml-4">
            <h1 className='md:text-2xl sm:text-2xl text-2xl text-[#ffffff] font-bold py-2'>
              VISION
            </h1>
            <p>We are committed to building communities around lifetime brands.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
