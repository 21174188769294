import React from 'react';
import ph from '../../assets/NEWS2424.jpg'; // Make sure to import the background image

const Public = () => {
  return (
    <div className='w-screen h-screen relative' style={containerStyle}>
      <div className='absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${ph})` }} />
      <div className='absolute top-0 left-0 w-full h-full bg-black opacity-30' />
      <div className='relative max-w-[1440px] mx-auto grid md:grid-cols-2'>
        {/* Cellphone image */}
        <div className='flex justify-center'>
          {/* Your content */}
        </div>
        {/* Text content */}
        <div className='flex flex-col justify-center'>
          {/* Your content */}
        </div>
      </div>
    </div>
  );
};

const containerStyle = {
  border: '1px solid transparent', // Add transparent border
  borderRadius: '10px', // Add border radius for smoother edges
  backgroundImage: 'linear-gradient(to right, rgba(20, 84, 255, 0.5), rgba(49, 52, 255, 0.34))', // Adjust gradient opacity
};

export default Public;
