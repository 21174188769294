import React from 'react';
import Hero from "./Hero";

import Analytics from "./Analytics";
import Newsletter from "./Newsletter";
import Company from "./Company";
import Public from "./Public";
import Twitter from "./Twitter";




const Home = () => {
  return (
    <div>
   
   <Company/>

   <Hero/>
   <Twitter/>
   <Analytics/>
  
   <Public/>
 
 <Newsletter/>

    </div>
  );
};

export default Home;
