import React from 'react';
import office2 from '../../assets/program.mp4';

const Analytics = () => {
  return (
    <div className='w-full bg-black py-16 px-4 relative'>
      <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${office2})`, opacity: '0.1' }} />
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        {/* Cellphone image */}
        <div className='flex justify-center'>
          <video className='w-[500px] my-4 mr-2' autoPlay loop muted style={{ opacity: '0.4' }} preload="auto">
            <source src={office2} type='video/mp4' />
          </video>
        </div>
        {/* Text content */}
        <div className='flex flex-col justify-center'>
          <h1 className='md:text-5xl sm:text-3xl text-1xl text-[#ffffff] font-bold py-1'>Website Design and Development</h1>
          <p className='sm:text-1xl text-[#f9fc3f] font-bold '>Transforming Ideas into Digital Realities: Experience Seamless Website Development</p>
          <p className='text-[#ffffff93] text-sm'>
          Our website development services are essential for establishing a professional online presence, attracting and engaging website visitors, and achieving specific business goals, such as generating leads, driving sales, or sharing information. Professional website development helps businesses showcase their products or services, increase brand visibility, and create a positive user experience, ultimately contributing to business growth and success.
          </p>
          
          <div className='text-[#ffffff93]'>
            <h2 className='text-[#ffffff] text-2xl font-bold my-4'>Our Services Include:</h2>
            <ul className='list-disc pl-6'>
              <li>WEB DESIGN: creating visually appealing and user-friendly website layouts and interfaces that align with the client's brand identity and goals. This includes selecting color schemes, fonts, graphics, and overall visual elements.</li>
              <li>FRONT-END DEVELOPMENT: writing code using programming languages such as HTML, CSS, and JavaScript to build the structure, layout, and interactive features of a website that users can see and interact with.</li>
              <li>BACK-END DEVELOPMENT: developing the server-side functionality of a website using programming languages such as PHP, Python, Ruby, or .NET. This includes database management, server configuration, and integration of advanced features such as content management systems (CMS) or e-commerce platforms.</li>
              <li>CONTENT CREATION: assisting in the creation, editing, and optimization of website content, including text, images, videos, and other media to ensure it is engaging, informative, and search engine friendly.</li>
              <li>RESPONSIVE DESIGN: developing websites that are optimized and adaptable for various devices, such as desktop computers, tablets, and mobile phones, to ensure a consistent and seamless user experience across different screen sizes.</li>
              <li>SEARCH ENGINE OPTIMIZATION (SEO): implementing strategies to optimize websites for search engines, improving their visibility and organic rankings in search engine results pages. This includes keyword research, on-page optimization, and technical optimizations.</li>
              <li>WEBSITE TESTING AND QUALITY ASSURANCE: conduct thorough testing and quality assurance checks to ensure the website functions properly, displays correctly across different browsers, and delivers a satisfactory user experience.</li>
              <li>WEBSITE MAINTENANCE AND UPDATES: providing ongoing support, maintenance, and updates to websites, including security patches, bug fixes, content updates, and performance optimizations.</li>
            </ul>
          </div>
   
        </div>
        
      </div>
      
    </div>
    
  );
};

export default Analytics;
