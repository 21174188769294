import React from 'react';
import { Link } from 'react-router-dom';
import cellphone from '../../assets/tarsee.png';
import { ReactTyped } from "react-typed";
import ph from '../../assets/ai2424.jpg'; // Make sure to import the background image

const Analytics = () => {
  return (
    
    <div className='w-full h-full relative py-60 px-20' style={containerStyle}>
      
      <div className='absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${ph})` }} />
      <div className='absolute top-0 left-0 w-full h-full bg-black opacity-70' />
     
      <div className='relative max-w-[1440px] mx-auto grid md:grid-cols-2'>
        {/* Cellphone image */}
        <div className='flex justify-center'>
          <img className='w-[500px] my-4 mr-2 animate__animated animate__bounce animate__slow' src={cellphone} alt='/' />
        </div>
        
        {/* Text content */}
        <div className='flex flex-col justify-center'>
        <ReactTyped className='md:text-4xl sm:text-5xl text-[#fffffff6] text-xl font-bold md:pl-4 pl-2' strings={["ADVERTISE WITH DAILY TRIBUNE"]} typeSpeed={40} backSpeed={140} loop />
        
          <p className='text-[#ffffffb2]'>
          Bringing you the latest and relevant news around the world
          </p>
          <Link to="/">
            <button className='relative bg-black-400 bg-opacity-10 text-[#ffffff] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>
              GET STARTED
              <span className="absolute bottom-0 left-0 h-[3px] w-full rounded-full bg-gradient-to-r from-black via-[#ff313156] to-black"></span>
            </button>
            
          </Link>
        </div>
      </div>
    </div>
  );
};

const containerStyle = {
  border: '1px solid transparent', // Add transparent border
  borderRadius: '10px', // Add border radius for smoother edges
  backgroundImage: 'linear-gradient(to right, rgba(20, 84, 255, 0.5), rgba(49, 52, 255, 0.34))', // Adjust gradient opacity
};

export default Analytics;
