import React, { useEffect } from 'react';
import logo from '../../assets/dailytribune.png'; // Import your logo
import AOS from 'aos';
import bgVideo from '../../assets/b3.mp4'; // Make sure to import the background video
import 'aos/dist/aos.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Grid, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import TikTokIcon from '../../assets/tiktok.png';
import XLogo from '../../assets/x.png';
import ViberIcon from '../../assets/viber.png';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent', // Transparent background
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Center content vertically
  height: '100%', // Ensure the height stretches to fill the container
}));

const SocialButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  display: 'block',
  width: '100%', // Make the button fill the width
  backgroundColor: 'rgba(41, 98, 255, 0.1)', // Transparent blue background
  border: 'none', // No border
  color: '#2962FF', // Blue text color
  transition: 'background-color 0.3s, color 0.3s', // Smooth transition
  '&:hover': {
    backgroundColor: 'rgba(41, 98, 255, 0.3)', // Slightly less transparent blue background on hover
    color: '#fff', // White text color on hover
  },
}));

const Company = () => {
  useEffect(() => {
    AOS.init();
    
    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='relative h-screen'>
<video autoPlay loop muted className='absolute top-0 left-0 w-full h-full object-cover' preload="auto">
  <source src={bgVideo} type="video/mp4" />
  {/* Fallback image if video fails to load */}
</video>


      <div className='absolute inset-0 bg-black opacity-50'></div>
      {/* Content */}
      <div className='relative z-10 max-w-[850px] mx-auto text-center flex flex-col justify-center h-full px-4'>
        <img src={logo} alt="Company Logo" className='w-32 h-auto mx-auto mb-4 sm:w-40 md:w-48 lg:w-56' style={{ maxWidth: '80%', height: 'auto' }} />
        <h1 className='text-2xl sm:text-2xl md:text-4xl text-white font-bold py-2'>
        Follow & Subscribe for the Latest Trends
        </h1>
        <span className="block w-full h-[3px] rounded-full bg-gradient-to-r from-black via-white to-black mb-4 mx-auto"></span>
        <div>
          <div className='flex justify-center items-center'>
            <p className='text-white text-lg'>

            </p>
          </div>
       
          <Grid container spacing={2} style={{ justifyContent: 'center' }}>
            <Grid item xs={6} sm={4}>
              <Item>
                <FacebookIcon style={{ fontSize: 40, color: '#1877F2', marginBottom: 8 }} />
                <SocialButton
                  variant="contained"
                  color="primary"
                  href="#"
                  target="_blank"
                >
                  Follow us
                </SocialButton>
                <p style={{ color: '#ffffff' }}>Follow us on Facebook</p>
              </Item>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Item>
                <YouTubeIcon style={{ fontSize: 40, color: '#FF0000', marginBottom: 8 }} />
                <SocialButton
                  variant="contained"
                  color="primary"
                  href="#"
                  target="_blank"
                >
                  Follow us
                </SocialButton>
                <p style={{ color: '#ffffff' }}>Follow us on YouTube</p>
              </Item>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Item>
                <InstagramIcon style={{ fontSize: 40, color: '#E1306C', marginBottom: 8 }} />
                <SocialButton
                  variant="contained"
                  color="primary"
                  href="#"
                  target="_blank"
                >
                  Follow us
                </SocialButton>
                <p style={{ color: '#ffffff' }}>Follow us on Instagram</p>
              </Item>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Item>
                <img src={TikTokIcon} alt="TikTok Logo" style={{ height: 40, marginBottom: 8 }} />
                <SocialButton
                  variant="contained"
                  color="primary"
                  href="#"
                  target="_blank"
                >
                  Follow us
                </SocialButton>
                <p style={{ color: '#ffffff' }}>Follow us on TikTok</p>
              </Item>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Item>
                <img src={ViberIcon} alt="Viber Logo" style={{ height: 40, marginBottom: 8 }} />
                <SocialButton
                  variant="contained"
                  color="primary"
                  href="#"
                  target="_blank"
                >
                  Follow us
                </SocialButton>
                <p style={{ color: '#ffffff' }}>Follow us on Viber</p>
              </Item>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Item>
                <img src={XLogo} alt="X Logo" style={{ height: 40, marginBottom: 8 }} />
                <SocialButton
                  variant="contained"
                  color="primary"
                  href="#"
                  target="_blank"
                >
                  Follow us
                </SocialButton>
                <p style={{ color: '#ffffff' }}>Follow us on X</p>
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Company;
