import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Hero from './components/Home/Hero';
import Offer from './components/Home/Offer';
import Analytics from './components/Home/Analytics';
import Newsletter from './components/Home/Newsletter';
import Website from './components/Website/Website';
import Relation from './components/PublicRelation/PublicRelation';
import Company from './components/Home/Company';
import Home from './components/Home/Home';
import About from "./components/About/Abouts";
import Twitter from "./components/Home/Twitter";
import Public from "./components/Home/Public";
import Digitalization from "./components/Digital/Digital";






function App() {
  return (
    <div>
      <Router>
 
        <Routes>
          
        <Route path="/" element={<Home />} />
          <Route path="/Hero" element={<Hero />} />
          <Route path="/Abouts" element={<About />} />
          <Route path="/Offer" element={<Offer />} />
          <Route path="/Public" element={<Public/>} />
          <Route path="/Twitter" element={<Twitter/>} />
          <Route path="/Analytics" element={<Analytics />} />
          <Route path="/Newsletter" element={<Newsletter />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/Digital" element={<Digitalization />} />
          <Route path="/PublicRelation" element={<Relation />} />
          <Route path="/Website" element={<Website />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
